
@charset "UTF-8";

@import "../../utilities/variables";
@import "../../utilities/mixins";
@import "../../support/slick";


/*
// _modal.scss 이동

.ui_modal_wrap {
    &.init-type {
        display: flex;
        margin:0;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        background: rgba(0,0,0,0.7);

        .popup-wrap {
            position: relative;
            height: 660px;
            max-height:none;
            border-radius:8px;

            &.small{
                left: inherit !important;
                top: initial !important;
                transform: initial !important;
                height: auto; 
                .pop-conts {
                    padding: 32px 16px 40px;
                }  
    
                .pop-footer {
                    .btn-close {
                        min-width:100px;
                        padding: 15px 0;
                        font-size: 16px;
                        line-height: 24px;
                        border-radius: 30px;
        
                    }
                }
            }

            &.active + .active {
                margin-left:24px;
            }

            .pop-conts {
                overflow:auto;
                height:calc(100% - 78px - 116px);

                &.support {
                    > .mCustomScrollBox {
                        > .mCSB_container {
                            padding: 0 40px;
                        }
                    }
                    @include mobile {
                        padding: 0 16px;
                    }
                }
                &.mCustomScrollbar {
                    overflow:hidden;
                    padding:0;
                    > .mCustomScrollBox {
                        > .mCSB_container {
                           padding:48px 40px 0
                        }
                        > .mCSB_scrollTools {
                           right:10px;
                        }
                    }
                }
            }
            
            .img-box {
                a {
                    display: block;
                }

                .pc-img {
                    display: block;
                    @include mobile {
                        display: none;
                    }
                }
                .mo-img {
                    display: none;

                    @include mobile {
                        display: block;
                    }
                }
                img {
                    width:100%;
                }
            }
            
            .headline {
                margin-bottom:24px;
                line-height:1.63;
                &+.text-cont {
                    margin-top:32px;
                }
            }
            .text-cont {
                // margin-top:32px;
                font-size:16px;
                line-height:1.63;
            }
                
            .video-container {
                .video-box {
                    position: relative;
                    height:0;
                    padding-top:56.25%;
                    margin-bottom:32px;
                    iframe, video {
                        position: absolute;
                        top:0;
                        left:0;
                        width:100%;
                        height:100%;
                    }
                }
                .video-tit {
                    font-size:24px;
                    font-weight:bold;
                    color:#000;
                    line-height:1.42;
                }
    
                .video-figure {
                    overflow:auto;
                    height:160px;
                    margin-top:15px;
                    padding:3px 0;
                    border:1px solid #ddd;
                    border-radius:10px;
                    box-sizing:border-box;

                    &.mCustomScrollBox {
                        overflow:hidden;
                    }
    
                    .figure-inner {
                        padding:24px;
                        font-size:14px;
                        line-height:1.71;
                        word-break: break-all;
                        white-space: pre-line;
                    }
                }

            }
            .btn-wrap {
                font-size:0;
                margin-top:24px;
                text-align: center;

                .btn {
                    margin:0 4px;
                }
            }

            .btn {
                &.full {
                    width:100%;
                    max-width:none;
                }
            }
        }
        .pop-footer {
            padding:26px 40px 40px;

        }

    }
    @include tablet {
        &.init-type {
            overflow:auto;
            padding:40px;

            &.center-only {
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
    
            .popup-wrap {
                height:506px;
                margin:0 auto;

                &.active + .active {
                    margin-left:auto;
                    margin-top:24px;
                }

                .pop-conts {
                    height:calc(100% - 78px - 76px);
                    &.mCustomScrollbar {
                        padding:0;
    
                        > .mCustomScrollBox > .mCSB_container {
                            padding:40px 40px 10px
                        }
                    }
                }

                .video-container {
                    .video-figure {
                        height:160px;
                        margin-top:12px;
                    }
    
                }
             
                .pop-footer {
                    padding:10px 16px;
                    .btn-close {
                        min-width:100px;
                        padding: 15px 0;
                        font-size: 16px;
                        line-height: 24px;
                        border-radius: 30px;
                    }
                }
            }
        }
    }

    @include mobile {
        &.init-type {
            padding:40px 16px;
    
            .popup-wrap {

                .pop-conts {
                    height:calc(100% - 138px);
                    padding:40px 16px 10px;
                    &.mCustomScrollbar {
                        padding:0;
    
                        > .mCustomScrollBox {
                            > .mCSB_container {
                                padding:40px 16px 10px
                            }
                            > .mCSB_scrollTools {
                               right:2px;
                            }
                        }
                    }
                }

                .video-container {
                    .video-tit {
                        font-size:20px;
                    }
        
                    .video-figure {
                        height:160px;
                        margin-top:12px;
        
                        .figure-inner {
                            padding:16px;
                            font-size:12px;
                        }
                    }
    
                }
             
                .pop-footer {
                    
                    input + label {
                        font-size:14px;
                    }
                }
            }
        }
    }
}
*/

.support-home {
    overflow:hidden;
    //padding-bottom:100px;
    @include mobile{
        padding-bottom:0;
    }
    
    section {
        &.section {
            //BTOCSITE-70096 고객지원 > 메인 리뉴얼
            //ODC 소모품
            @media screen and (max-width: 767px) {
                section[class*='odc'] {
                    margin-top: 40px;
                }
            }
        }
    }
    .section {
        & ~ .section {
            border-top: 8px solid #f8f8f8;
            margin-top: 40px;
        }
    }
    /* BTOCSITE-1433 - App> 고객지원 Main, pinch out 기능 제공 */
    .slick-slider{
        touch-action: auto;
    }
    .slick-dots {
        li {
            button, a {
                display: inline-block;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                border: 0;
                background: #8f8f8f;
                vertical-align: top;
                font-size: 6px;
                color:transparent;
                overflow: hidden;
            }
            &.slick-active {
                button, a {
                    background: #0F0F0F;
                    @include mobile {
                        width: 20px;
                        border-radius: 6px;
                    }
                }
            }
        }
    }
    /* //BTOCSITE-1433 - App> 고객지원 Main, pinch out 기능 제공 */
    .btn {
        &.light-gray {
            background: #aaa;
            border-color:#aaa;
            color:#fff;
        }
        &.plus {
            span {
                position: relative;
                display: inline-block;
                padding-right: 20px;
                &::before {
                    content: '';
                    position: absolute;
                    right: 0;
                    top: 9px;
                    width: 10px;
                    height: 1px;
                    background: #fff;
                }
                &::after {
                    content: '';
                    position: absolute;
                    right: 0;
                    top: 9px;
                    width: 10px;
                    height: 1px;
                    background: #fff;
                    transform: rotate(90deg);
    
                }
            }
        }
    }


    .btn-fast-support {
        display: inline-block;
        padding:12px 24px 12px;
        font-size:16px;
        line-height:32px;
        font-weight:500;
        color:#000;
        background: #fff;
        border-radius:30px;
        vertical-align: middle;

        &:before {
            content:"";
            display:inline-block;
            width:32px;
            height:32px;
            margin-right:4px;
            background: url("/lg5-common/images/CS/icon-install-action-support.svg") no-repeat 0 0/100% 100%;
            vertical-align: middle;
        }

        @include mobile {
            padding:11px 20px 10px;
            font-size:13px;
            border-radius:30px;

            &:before {
                width:24px;
                height:24px;
            }
        }
    }

    .btn-moreview {
        &.close {
            &:before {
                background-image: url("/lg5-common/images/icons/btn-minus.svg");
            }
        }
    }   

    // BTOCSITE-51308 고객지원 홈 ] (모바일 버전) 하단 배너 노출 빈도 개선
    //관련된 정보
    .related-pages {
        &.common-banner {
            padding-top: 0;
            margin-top: 32px;
            padding: 0 16px;
            .banner {
                padding: 0;
            }
            .banner-tit {
                display: none;
            }
        }
        .page-list-wrap {
            position: relative;
        }
        .item {
            // float:left;
            position: relative;
            // width:33.33%;
            // padding:0 12px;
            &:not(:first-of-type){
                display: none;
            }
            &:not(:last-of-type){
                margin: 0;
            }
            &.hidden {
                display:none;

                &.show {
                    display: block;
                }
            }

            &-list {
                position:relative;
                // margin-left:-12px;
                // margin-right:-12px;

                @include clearfix;
            }

            &-cont {
                position: absolute;
                top:0;
                left:0;
                display:flex;
                align-items: center;
                width:100%;
                height:100%;
                padding:35px 96px;
                box-sizing:border-box;

                font-size:24px;
                font-weight:bold;
                color:#000;
                line-height:32px;
                .font-white{
                    color: white;
                }
                .font-small {
                    font-size:13px;
                }
            }

            > a {
                display:block;
                position: relative;
                overflow:hidden;
                border-radius:8px;
                //width:444px;
                // height: 180px;

                img {
                    // position: absolute;
                    // top:50%;
                    // left:50%;
                    // transform: translate(-50%,-50%);
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }
        .slick-initialized {
            .item {
                &:not(:first-of-type){
                    display: block;
                }
            }
        }
        .slick-arrow {
            &:before {
                box-shadow:none;
                opacity: 0.8;
            }
        }
        .slick-dots {
            bottom: 10px;
        }
        .slick-slide {
            @include pc {
                img {
                    &.mo-only {
                        display: none !important;
                    }
                }
            }
            @include mobile {
                img {
                    &.pc-only {
                        display: none !important;
                    }
                }
            }
        }
        .slide-count {
            position: absolute;
            bottom: 10px;
            right: 50px;
            display:inline-block;
            border-radius:10px;
            height:20px;
            padding:0 12px;
            background:rgba(0,0,0,0.35);
            color:#fff;
            font-size: 10px;
            line-height:20px;
        }
        .btn-play {
            position: absolute;
            bottom:10px;
            right:20px;
            display: inline-block;
            width:20px;
            height:20px;
            background-size: 20px 20px;
            background-position: center center;
            background-repeat:no-repeat;
            background-image: url('/lg5-common/images/icons/btn-pause-16-gray.svg');
            opacity: 0.8;
            &.pause {
                background-image: url('/lg5-common/images/icons/btn-play-16-gray.svg');
            }
        }
        // BTOCSITE-14560 고객지원 하단 배너 2단 노출 시 padding 처리
        // @include pc {
        //     .item {
        //         //&:nth-child(n+4) {
        //            //margin-top: 12px;
        //         //}
        //     }
        // }
        @include pc-size-m {
            .item {
                &:not(:last-of-type){
                    margin: 0;
                }
                &-cont {     
                    padding:30px 96px;
                    font-size:20px;
                }
            }
        }
        @include tablet {
            .item {
                &-cont {
                    padding:0 20px;
                    font-size:16px;
                    line-height: 1.4;
                    .font-white{
                        color: white;
                    }
                    // .font-small {
                    //     font-size:10px;
                    // }
                }
            }
        }
        @include mobile {
            .item {
                &-cont {
                    padding:0 14px;
                }
            }
        }
        .btn-moreview {
            display:none !important;
        }

    } //.related-pages

    // .common-banner {
    //     margin-top:100px;
    //     .banner {
    //         .banner-tit {
    //             margin-bottom:32px;
    //             text-align:left;
    //             @include mobile{                
    //                 margin-bottom:19px;
    //             }
    //         }
    //     }
    //     &.main-service, &.awards {
    //         display:none !important;
    //     }
    // }


    //고객지원 홈 하단 배너영역들 모바일 css
    // @include mobile {
    //     .common-banner {
    //         margin-top:0;
    //         padding-top:58px;
    //         border-top:0;
    //         .banner-tit {
    //             font-size:20px;
    //             line-height: 26px;
    //         }
    //     }
    // }

    // 상단 로그인 툴팁 박스
    .tooltip {
        &-login {
            display: block;
            max-width:100%;
            margin:0 auto;
            background-color: #DA0F47; //BTOCSITE-50181 상단 띠배너 추가] 이브닝 서비스 홍보 활동 지속 // BTOCSITE-14407 WSG 수정 : 그라데이션 사용 제거 건
            // BTOCSITE-24154 [로그인유도] 고객지원 홈 띠배너 (Hello Bar) 노출을 모바일 웹/앱으로 확대
            // @include mobile {
            //     display:none; //0625
            // }
        }
        &-inner {
            position: relative;
            max-width:1380px;
            margin:0 auto;
            padding:17px 0;
            text-align: center;
            p {
                display: inline-block;
                color:#fff;
                font-size:16px;
                line-height:26px;
                vertical-align: middle;
                strong {
                    font-weight:bold;
                }
            }

            .btn-link {
                margin-left:16px;
                color:#fff;
                font-weight:bold;
                text-decoration: none;

                &:after {
                    width:16px;
                    height:16px;
                    background-image: url("/lg5-common/images/icons/btn-arr-16-white.svg");
                    background-size:100% 100%;
                }
                
            }
            .btn-tooltip-close {
                position: absolute;
                top:50%;
                right:0;
                transform:translateY(-50%);
                width:48px;
                height:48px;
                background: url("/lg5-common/images/icons/btn-close-24-white.svg") no-repeat center center/24px 24px;
            }
            @include mobile {
                text-align: left;
                padding:10px 16px; // BTOCSITE-24154 [로그인유도] 고객지원 홈 띠배너 (Hello Bar) 노출을 모바일 웹/앱으로 확대
                p {
                    display: block;
                    font-size:13px;
                }
                .btn-link {
                    margin-left:0;
                }
            }
        }
    }


    //고객지원 홈 컨텐츠 섹션 
    .home-section {
        max-width:1920px;
        margin:0 auto;
        margin-top:100px;

        img {
            width:100%;
        }

        .section-inner {
            position:relative;
            max-width:1460px;
            margin:0 auto;
            padding:0 40px;
        }

        .section-row {
            display:flex;
            flex-direction: row;

            .section-col {
                flex:1;
                width:50%;

                & + .section-col {
                    margin-left:24px;
                }
            }
        }

        @include tablet {
            margin-top:80px;

            .section-row {
                display: block;

                .section-col {
                    width:100%;
                    & + .section-col {
                        margin-top:24px;
                        margin-left:0;
                    }
                }
            }
        }

        @include mobile {
            margin-top:60px;
            
            .section-inner {
                padding:0 16px;
            }
            
        }
        &.popular-find {
            margin-top:0;
        }

        
    }

    //히어로 or 제품 지원 영역 상단
    .home-cont-top {
        max-width: 665px;
        padding:0 40px;
        text-align: left;
        z-index:1;
        box-sizing:border-box;
        .cont-sub-copy {
            font-size:24px;
            line-height: 32px;
            margin:16px 0 0;
            font-weight: 500;
        }
        .btn-fast-support {
            display: none;
            margin-left:24px;
        }

        @include tablet {
            .hero-tit {
                font-size:14px;
            }
            
        }

        @include mobile {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            top:32px; 
            left:0;
            padding:0 16px;

            .cont-top-tit {
                font-size:28px;
                line-height: 36px;
            }
            .cont-sub-copy {
                font-size:16px;
                line-height: 24px;
                max-width: 268px; //220118 수정
            }
            .btn-fast-support { 
                margin-left:0;    
            }
        }
    }

    
    // 히어로 영역 or 컨텐츠 영역 내에 들어가는 제품 지원 컨텐츠
    .support-nav-container {
        position: relative;
        max-width:1460px;
        height:100%;
        margin:0 auto;
        padding:48px 40px;
        justify-content: center;

        .support-list-slider {
            position: relative;
            height: 100%;
        }

        @include mobile {
            padding:33px 16px 24px; //0625
        }
    }

    .support-toggle-list-wrap {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        position: relative;
        height:100%;

        .support-list {
            .list-header {
                position: relative;
                .list-tit {
                    display: block;
                    font-size:32px;
                    color:#fff;
                    font-weight:bold;
                    line-height:1.6;
                    text-shadow: 0px 1px 20px rgba(0, 0, 0, .5); //0625
                }
                /* BTOCSITE-7016 */
                .btn-list-toggle {
                    display: inline-block;
                    position: absolute;
                    top:0; 
                    left:0;
                    right:0;
                    width:100%;
                    height:100px;
                    vertical-align: top;
                    &:after{
                        content:'';
                        position:absolute;
                        top:0;
                        right:0;
                        width:56px;
                        height:56px;
                        background: url("/lg5-common/images/CS/btn-arr-down-56-white.svg") no-repeat 0 0/100% 100%;
                    }
                }
                /* //BTOCSITE-7016 */
            }           

            ul {
                display: none;
                width:342px;
                position: relative;
                font-size:0;
                margin-top:20px;

                @include clearfix;

                > li {
                    float:left;
                    width:50%;
                    padding:4px;

                    a {
                        display: inline-block;
                        width:100%;
                        height:48px;
                        line-height:46px;
                        text-align: center;
                        font-size:14px;
                        color:#fff;
                        text-align: center;
                        border:1px solid #fff;
                        border-radius:30px;
                        transition:.6s color, .6s background-color;

                        &:hover {
                            color:#000;
                            background: #fff;
                        }
                        
                    }
                }
            }
          
        }

        &.only-desktop {
            .support-list {
                float:none !important;
                width:436px;
                display: flex;
                flex-direction: column;

                &.product-support {
                    padding-right:61px;
                }

                &.consult {
                    
                }

                &.reservation {
                    padding-left:63px;
                }

                .list-header {
                    padding-right:60px; //BTOCSITE-7016
                    .list-tit {
                        color:rgba(255,255,255,0.6);
                        // color:#fff;
                        transition:.6s color;
                        // text-shadow: #666 0px 2px 5px;

                        .txt-highlight {
                            color:#fff;
                            transition:.6s color;
                        }
                    }
                }

                &.active {
                    .list-header {
                        .list-tit {
                            color:#fff;
                        }
                        /* BTOCSITE-7016 */
                        .btn-list-toggle {
                            &:after{
                                transform:rotate(-180deg);
                            }
                        }
                        /* //BTOCSITE-7016 */
                    }
                }
                &.siblings {
                    .list-header {
                        .list-tit {
                            //color:rgba(255,255,255,0.4);

                            .txt-highlight {
                                //color:rgba(255,255,255,0.4);
                            }
                        }
                    }
                }
            }

            @include pc-size-l {
                
                .support-list {
                    width:365px;
                    .list-header {
                        padding-right:50px;
                        .list-tit {
                            font-size:26px;
                        }
                        /* BTOCSITE-7016 */
                        .btn-list-toggle {
                            &:after{
                                width:40px;
                                height:40px;
                            }
                        }
                        /* //BTOCSITE-7016 */
                    }
                    ul {
                        width:295px;
                        > li {
                            a {
                                font-size:12px;
                            }
                        }
                    }
                }
            }

            @media screen and (max-width:1280px) {
                .support-list {
                    width:290px;

                    &.product-support {
                        padding-right:0;
                    }
    
                    &.consult {
                        
                    }
    
                    &.reservation {
                        padding-left:0;
                    }

                    .list-header {
                        .list-tit {
                            font-size:22px;
                        }
                    }
                    ul {
                        width:260px;
                    }
                }
            }
        }

        @include tablet {
            display: block;
            @include clearfix;
            .support-list {
                float:left;
                width:100%;
                padding:60px 0;
                text-align: center;

                .list-header {
                    .list-tit {
                        font-size:32px;
                    }
                    .btn-list-toggle {
                        display:none;
                    }
                }
                
    
                ul {
                    display: block !important;
                    width:342px;
                    margin:20px auto 0;

                    > li {
                        a {
                            height:48px;
                            line-height:46px;
                        }
                    }
                }
            }

            .slick-dots {
                bottom:15px;
            }
        }

        @include mobile {
            .support-list {
                padding:0 0 60px; //0625
                width:100%;
                max-width:calc(100vw - 32px);
                opacity:1;
                text-align: center;

                .list-header {
                    .list-tit {
                        font-size:25px; //0625
                        line-height: 32px; //0625
                    }
                }
                
                ul {
                    max-width:336px;
                    width:100%;
                    margin: 18px auto 0; //0625
                    > li {
                        a {
                            font-size:13px;
                            height:43px;
                            line-height:41px;
                        }
                    }
                }
            }

            .slick-dots {
                bottom:33px; //0625
            }
        }
    }

    // 히어로 영역 로그인 후 보유 제품 슬라이드
    .had-pd-container {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width:1380px;
        margin:0 auto;
        height:100%;
        
        .had-pd-inner {
            width:100%;
            margin:0 auto;
            padding:0 93px;
        }

        .headline {
            .tit {
                margin-bottom:24px;
                font-size:24px;
                line-height:1.6;
                font-weight:bold;
                color:#fff;
            }
        }
        .had-pd-slider {
            overflow:hidden;
            height:240px;
            $defaultPadding : 35px 32px 48px;

            @include mobile {
                margin:0 -16px;
                height:200px;
            }

            &.slick-initialized {
                overflow:visible;
                height:auto;

                @include mobile {
                    height:auto;
                }
            }

            .slick-list {
                margin:0 -12px;

                @include mobile {
                    margin:0;
                }
            }

            .slick-track {
                display: flex;
                flex-direction: row;
                position: relative;

                .item-list {
                    float:none;
                }
            }

            .slick-arrow {                
                &.slick-prev {
                    left:-85px;
                }

                &.slick-next {
                    right:-85px;
                }
            }

            .item-list {
                display:inline-block;
                padding:0 12px;
                border-radius:10px;
                text-align: left;
                vertical-align: top;

                &.default-type {           
                    // BTOCSITE-14407 WSG 수정 : 그라데이션 사용 제거 건     
                    .card-front {
                        background-color: #fff;
                    }

                    
                }

                &.history-type {
                    .item-card {
                        padding:$defaultPadding;
                        background: rgba(255,255,255,0.8);
                    }
                    .card-bottom {
                        text-align: right;
                    }
                }

                &.regist-type {
                    .item-card {
                        text-align: center;
                        padding:$defaultPadding;
                        background: rgba(255,255,255,0.8);
                    }
                }
            }
            .item-card {
                display: block;
                position: relative;
                width:382px;
                height:240px;
                border-radius:10px;

                .card-front, .card-back {
                    display: block;
                    position: absolute;
                    top:0;
                    left:0;
                    width:100%;
                    height:100%;
                    
                    box-sizing:border-box;
                    border-radius:10px;
                }

                .card-tit {
                    display: block;
                    margin-bottom:17px;
                    font-size:20px;
                    line-height:1.6;
                    color:#000;
                    font-weight:bold;
                }

                .card-desc {
                    display: block;
                    margin-top:8px;
                    font-size:16px;
                    line-height:1.63;
                }
                
                .icon-regist {
                    display: inline-block;
                    width:64px;
                    height:64px;
                    background: url("/lg5-common/images/CS/icon-pd-regist.svg") no-repeat 0 0/100% 100%;   
                }

                .card-info-box {
                    display: flex;
                    flex-direction: row;

                    .card-pd {
                        &-info {
                            margin-left:16px;
                            flex:1;
                            line-height:1.4;
                            font-size:14px;
                            color:#222;

                            span, strong {
                                display: block;
                            }
                        }
                        &-tit {
                            font-size:16px;
                            font-weight:bold;
                        }
                    }
                }

                .card-btn-wrap {
                    margin-top:20px;

                    .btn-link {
                        & + .btn-link {
                            margin-left:12px;
                        }
                    }
                }

                .card-bottom {
                    position: absolute;
                    left:0;
                    bottom:0;
                    padding: 14px 16px;
                    width:100%;
                    font-size:14px;
                    color:#000;
                    height:48px;
                    box-sizing:border-box;
                }

                .card-front {
                    padding:$defaultPadding;
                    color:#000; // BTOCSITE-14407 WSG 수정 : 그라데이션 사용 제거 건
                    .pd-category {
                        margin-bottom:2px; // BTOCSITE-14407 WSG 수정 : 그라데이션 사용 제거 건
                        font-size:16px;
                        line-height:1.63; // BTOCSITE-14407 WSG 수정 : 그라데이션 사용 제거 건
                        // BTOCSITE-14407 WSG 수정 : 그라데이션 사용 제거 건
                        .thumb {
                            position: absolute;
                            right: 32px;
                            bottom: 52px;
                            width: 80px;
                            height: 80px;
                            background-color: #f4f4f4;
                            border-radius: 50%;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            img {
                                width:70%;
                            }
                        }
                    }
                    
                    .pd-name {
                        display: block;
                        font-size:20px;
                        font-weight:bold;
                        line-height:1.4; // BTOCSITE-14407 WSG 수정 : 그라데이션 사용 제거 건
                        margin-bottom: 6px; // BTOCSITE-14407 WSG 수정 : 그라데이션 사용 제거 건
                    }
                    .pd-code {
                        display: block;
                        font-size:14px;
                        line-height: 1.71; // BTOCSITE-14407 WSG 수정 : 그라데이션 사용 제거 건
                        color: #E03967; // BTOCSITE-14407 WSG 수정 : 그라데이션 사용 제거 건
                    }
                    .btn-goto-more {
                        display: block;
                        position: absolute;
                        bottom: 13px;
                        right: 13px;
                        padding-right: 20px;
                        background: url(/lg5-common/images/icons/btn-arr-16-black.svg) no-repeat right 50%; // BTOCSITE-14407 WSG 수정 : 그라데이션 사용 제거 건
                        font-size: 16px;
                        line-height: 26px;
                        text-decoration: underline;
                        color: #000; // BTOCSITE-14407 WSG 수정 : 그라데이션 사용 제거 건
                    } // 05.10
                }

                .card-back {
                    display: none;
                    padding:32px 0 56px;
                    background: #f4f4f4; // BTOCSITE-14407 WSG 수정 : 그라데이션 사용 제거 건

                    &.active {
                        display: block;
                    }

                    &-cont {
                        position:relative;
                        overflow:auto;
                        padding:0 32px;
                        height:152px;

                        &.mCustomScrollbar {
                            padding:0;
                            .mCSB_container {
                                padding:0 32px;
                            }
                        }

                    }

                    .pd-info-list {
                        @include clearfix;

                        &:hover {
                            > li {
                                a {
                                    opacity:0.4;
                                    &:hover {
                                        opacity:1;
                                        text-decoration: underline;
                                    }
                                }
                            }
                        }

                        > li {
                            float:left;
                            width:50%;

                            a {
                                display: block;
                                font-size:14px;
                                line-height:30px;
                                color:#000;
                                font-weight:500;
                                transition:opacity .3s;
                            }
                        }
                    }
                }
                
                .btn-goto-back {
                    display: block;
                    overflow: hidden;
                    position: absolute;
                    bottom:16px;
                    right:16px;
                    width:24px;
                    height:24px;
                    border-radius: 50%;
                    background: url("/lg5-common/images/icons/btn-plus-48.svg") no-repeat 0 0/100% 100%;
                }

                .btn-goto-front {
                    display: block;
                    overflow: hidden;
                    position: absolute;
                    bottom:16px;
                    right:16px;
                    width:24px;
                    height:24px;
                    border-radius: 50%;
                    background: url("/lg5-common/images/icons/btn-delete-20.svg") no-repeat 0 0/100% 100%;
                }

                
            }
        }
        

        @include tablet {

            .headline {
                .tit {
                    font-size:20px;
                }
            }
            .had-pd-inner {
                padding:0 70px;
            }

            .had-pd-slider {
                //margin:0 -12px;
                $tabletPadding : 24px 21px 38px; // BTOCSITE-14407 WSG 수정 : 그라데이션 사용 제거 건

                .slick-dots {
                    bottom:-28px;
                }

                .slide-track {
                    height:200px;
                    margin:0 -12px;
                }
    
                .item-list {
                    padding:0 12px;
    
                    &.history-type {
                        .item-card {
                            padding:$tabletPadding;
                        }
                    }
    
                    &.regist-type {
                        .item-card {
                            padding:$tabletPadding;
                        }
                    }
                }
                .item-card {
                    width:320px;
                    height:200px;
    
                    .card-tit {
                        margin-bottom:12px;
                        font-size:16px;
                    }
    
                    .card-desc {
                        margin-top:8px;
                        font-size:14px;
                    }
                    
                    .icon-regist {
                        width:50px;
                        height:50px;
                    }
    
                    .card-info-box {
                        .card-pd {
                            &-info {
                                margin-left:12px;
                                font-size:13px;
                            }
                            &-tit {
                                font-size:14px;
                            }
                        }
                    }
    
                    .card-btn-wrap {
                        margin-top:20px;
    
                        .btn-link {
                            font-size:13px;
                            & + .btn-link {
                                margin-left:12px;
                            }
                        }
                    }
    
                    .card-bottom {
                        padding: 14px 16px;
                        font-size:14px;
                        height:48px;
                    }
    
                    .card-front {
                        position: relative; // BTOCSITE-14407 WSG 수정 : 그라데이션 사용 제거 건
                        padding:$tabletPadding;
                        .pd-category {
                            margin-bottom:4px; // BTOCSITE-14407 WSG 수정 : 그라데이션 사용 제거 건
                            font-size:14px;
                            line-height:1.6;
                            // BTOCSITE-14407 WSG 수정 : 그라데이션 사용 제거 건
                            .thumb {
                                width:64px;
                                height:64px;
                                right: 21px;
                                bottom: 37px;
                            }
                        }
        
                        .pd-name {
                            font-size:20px;
                            line-height: 1.3; // BTOCSITE-14407 WSG 수정 : 그라데이션 사용 제거 건
                            margin-bottom: 4px; // BTOCSITE-14407 WSG 수정 : 그라데이션 사용 제거 건
                        }
                        .pd-code {
                            font-size:13px; // BTOCSITE-14407 WSG 수정 : 그라데이션 사용 제거 건
                            line-height:1.62; // BTOCSITE-14407 WSG 수정 : 그라데이션 사용 제거 건
                        }
                    }
    
                    .card-back {
                        padding:20px 0 38px;
    
                        &-cont {
                            padding:0 20px;
                            height:132px;

                            &.mCustomScrollbar {
                                .mCSB_container {
                                    padding:0 20px;
                                }
                            }
    
                        }
    
                        .pd-info-list {
                            > li {
                                a {
                                    font-size:13px;
                                    line-height:28px;
                                }
                            }
                        }
                    }
                    
                    .btn-goto-back {
                        bottom:13px;
                        right:13px;
                    }
    
                    .btn-goto-front {
                        bottom:13px;
                        right:13px;
                    }
    
                    
                }
            }
        }

        @include mobile {
            overflow:hidden;
            padding:24px 16px 24px; //0625
            justify-content: flex-start; //0625

            .had-pd-inner {
                padding:0;
            }
            .headline {
                .tit {
                    font-size:18px; //0625
                    line-height:23px; //0625
                    margin-bottom: 10px; //0625
                    .user-name {
                        display: block;
                    }
                }
            }
            .had-pd-slider {
                
                $mobilePadding : 24px 21px 38px; // BTOCSITE-14407 WSG 수정 : 그라데이션 사용 제거 건
                .slide-content {
                    overflow:visible !important;
                }
                .item-list {
                    padding:0 6px;
                    &:nth-child(1) {
                        padding-left:16px;
                    }
                    &.history-type {
                        .item-card {
                            padding:$mobilePadding; // BTOCSITE-14407 WSG 수정 : 그라데이션 사용 제거 건
                        }
                    }
    
                    &.regist-type {
                        .item-card {
                            padding:$mobilePadding; // BTOCSITE-14407 WSG 수정 : 그라데이션 사용 제거 건
                        }
                    }
                }
                .item-card {
                    width:80vw;
                    height:200px;
    
                    .card-tit {
                        margin-bottom:4px;
                        font-size:20px;
                    }
    
                    .card-desc {
                        margin-top:4px;
                        font-size:14px;
                    }
                    
                    .icon-regist {
                        width:60px;
                        height:60px;
                    }
    
                    .card-info-box {
    
                        .card-pd {
                            &-info {
                                margin-left:12px;
                                font-size:13px;
                            }
                            &-tit {
                                font-size:14px;
                            }
                        }
                    }
    
                    .card-btn-wrap {
                        margin-top:14px;
    
                        .btn-link {
                            & + .btn-link {
                                margin-left:8px;
                            }
                        }
                    }
    
                    .card-bottom {
                        padding: 5px 12px 0;
                        font-size:13px;
                        height:36px;
                    }
                    
                    .card-front {
                        padding:$mobilePadding; // BTOCSITE-14407 WSG 수정 : 그라데이션 사용 제거 건
                        .pd-category {
                            // BTOCSITE-14407 WSG 수정 : 그라데이션 사용 제거 건
                            font-size:12px;
                            line-height: 1.75;
                            // BTOCSITE-14407 WSG 수정 : 그라데이션 사용 제거 건
                            .thumb {
                                width:64px;
                                height:64px;
                                right: 21px;
                            }
                        }
         
                        
                        .pd-name {
                            font-size:20px;
                            line-height: 1.3; // BTOCSITE-14407 WSG 수정 : 그라데이션 사용 제거 건
                        }
                        .pd-code {
                            font-size:13px;
                            line-height:1.62; // BTOCSITE-14407 WSG 수정 : 그라데이션 사용 제거 건
                        }
                        .btn-goto-more {
                            bottom: 12px;
                            right: 12px;
                            font-size: 13px;
                            line-height: 22px;
                            padding-right: 16px;
                            background: url(/lg5-common/images/icons/btn-arr-16-black-sm.svg) no-repeat right 50%; // BTOCSITE-14407 WSG 수정 : 그라데이션 사용 제거 건
                        }
                    }
    
                    .card-back {
                        padding:24px 0 49px;
    
                        &-cont {
                            padding:0 20px;
                            height:127px;
                            margin-right:3px;

                            &.mCustomScrollbar {
                                margin-right:0;
                                .mCSB_container {
                                    padding:0 20px;
                                }
                            }
    
                        }
    
                        .pd-info-list {
                            @include clearfix;
    
                            > li {
                                width:50%;
    
                                a {
                                    font-size:13px;
                                    line-height:32px;
                                }
                            }
                        }
                    }
                    
                    .btn-goto-back {
                        bottom:12px;
                        right:12px;
                    }
    
                    .btn-goto-front {
                        bottom:12px;
                        right:12px;
                    }
                }
                .slick-dots {
                    bottom:-24px; //0625
                }
            }
        }
    }
    
    
    //공지사항
    .home-notice {
        // BTOCSITE-9066 수정
        overflow:hidden;
        height:77px;
        border-top:1px solid #f4f4f4;
        // margin-top: 41px;
        .notice-inner {
            display:table;
            position: relative;
            max-width:850px;
            width:100%;
            margin:0 auto;
            //padding:0 40px;
            table-layout: fixed;
        }

        .notice-title {
            display:table-cell;
            width:70px;
            padding-right:10px;
            vertical-align: middle;
            .title {
                font-size:14px;
                line-height:20px; //BTOCSITE-9066 수정
                color:#000; //BTOCSITE-9066 수정
                font-weight:500;
            }
        }
        .slide-count {
            position: absolute;
            right: 25px;
            top: 26px;
            border-radius:10px;
            height:20px;
            padding:0 12px;
            background:rgba(0,0,0,0.35);
            color:#fff;
            font-size: 10px;
            line-height:20px;
        }
        .notice-list-wrap {
            display:table-cell;
            position: relative;
            width:100%;
            //padding:0 84px 0 16px;
            padding:0 106px 0 16px;
            font-size:16px;
            line-height: 24px;
            box-sizing:border-box;
            vertical-align: middle;
            opacity:0;
            transition:opacity .3s;
            color:#666; //BTOCSITE-9066 추가
            &.slick-initialized {
                opacity:1;
            }
            &:before {
                content:"";
                display:block;
                position: absolute;
                top:50%;
                left:0;
                transform:translateY(-50%);
                width:1px;
                height:12px;
                background: #ddd;
            }
            .flag-wrap {
                margin-right:6px;
                .flag {
                    font-size:12px;
                    line-height:1;
                    height:auto;
                }
            }
            .notice-list {
                height:71px;
                line-height:71px;
                display: flex;
                justify-content: flex-start;
                a{
                    @include textEllipsis;
                }
            }
            .slide-contents {
                overflow:hidden;
                height:70px;
            }
        }
        .slick-arrow {
            position: absolute;
            width:28px;
            height:28px;
            padding:6px;
            top:50%;
            transform:translateY(-50%);
            background-size: 24px 24px;
            background-position: center center;
            background-repeat:no-repeat;

            &:before {
                display: none;
            }

            &.slick-prev {
                left:auto;
                right:56px;
                background-image: url('/lg5-common/images/CS/icon-notice-up-24.svg');
            }
            &.slick-next {
                right:28px;
                background-image: url('/lg5-common/images/CS/icon-notice-down-24.svg');
            }
        }

        .btn-play {
            display: block;
            position: absolute;
            width:28px;
            height:28px;
            top:50%;
            right:0;
            transform:translateY(-50%);
            background-size: 20px 20px;
            background-position: center center;
            background-repeat:no-repeat;
            background-image: url('/lg5-common/images/icons/btn-pause-16-gray.svg');

            &.pause {
                background-image: url('/lg5-common/images/icons/btn-play-16-gray.svg');
            }
        }
        @include tablet {
            .notice-inner { 
               max-width: 100%;
               padding:0 10px;
            }

            .slide-count {
                top:25px;
            }

            .btn-play {
                right: -10px;
            }
        }
        @include mobile {
            .notice-inner {
                display:block;
                padding:12px 24px 18px;
            }

            .notice-title {
                display: block;
                .title {
                    font-size:14px;
                    line-height:1.63;
                }
            }
            .slide-count {
                top:-20px;
            }
            .notice-list-wrap {
                display: block;
                margin-left:0;
                padding:0;
                font-size:13px;
                &:before {
                    display: none;
                }

                .flag-wrap {
                    .flag {
                        font-size:11px;
                    }
                }

                .notice-list {
                    height:40px;
                    line-height:31px;
                }

                .slide-contents {
                    height:36px;
                }
            }

            .slick-arrow {
                top:-25px;
                transform:none;
                &.slick-prev {
                    background-size:24px 24px;
                }
                &.slick-next {
                    background-size:24px 24px;
                }
            }
            .btn-play {
                top:-25px;
                right:-10px;
                transform:none;
                background-size:20px 20px;
                padding:6px;
            }
        }
    }

    //검색영역
    .keyword-search {
        margin:48px 0;
        padding:0 40px;
        @include mobile{
            margin:19px -4px 7px;
        }
        .search-box {
            max-width:850px;
            width:100%;

            .btn-search {
                right:10px;
                height:100%;
                background: center/24px 24px url(/lg5-common/images/icons/icon-search-32.svg) no-repeat;
            }
            .btn-delete {
                right:70px;
            }
        }
        .search-input {
            width:100%;
            input[type=text]  {
                padding:18px 64px 18px 32px;
                border-radius:33px;
                background:#f4f5f7;
                box-shadow: none;
            }
            &.clear {
                input[type=text]  {
                    padding-right:90px;
                }   
            }
        }
        .autocomplete-box {
            .keyword-list {
                a {
                    display:block;
                    max-width:none;
                    @include clearfix;
                    .model {
                        float:left;
                    }
                    .category {
                        float:right;
                    }  
                }
            }
        }

        @include mobile {
            padding:0;
            .search-box {
                .btn-search {
                    right:17px;
                    // height:46px;
                    width:34px;
                }
                .btn-delete {
                    right:60px ;
                }
            }
            .search-input {
                input[type=text]  {
                    padding:12px 41px 12px 20px;
                }
                &.clear {
                    input[type=text]  {
                        padding-right:97px;
                    }   
                }
            }
            .autocomplete-box {
                .keyword-list {
                    a {
                        .category {
                            display:none;
                        }
                    }
                }
            }
        }
    }


    //컨텐츠 영역 로그인 박스
    .home-login {
        position:relative;
        padding:48px 0 54px;
        color:#fff; // BTOCSITE-14407 WSG 수정 : 그라데이션 사용 제거 건
        max-width: 100%;
        &:before {
            content:"";
            display: block;
            position:absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
            background-color: #ac4eac; // BTOCSITE-14407 WSG 수정 : 그라데이션 사용 제거 건
        }
        
        .section-inner {
            text-align: center;
        }

        .login-tit {
            margin-bottom:18px;
            font-size:32px;
            line-height:40px;
        }
        .login-useful-list {
            ul {
                font-size:18px;
                color:#fff; // BTOCSITE-14407 WSG 수정 : 그라데이션 사용 제거 건
                line-height:26px;

                > li {
                    & + li {
                        margin-top:10px;
                    }
                    &:before {
                        content:"";
                        display:inline-block;
                        width:24px;
                        height:24px;
                        margin-top:-2px;
                        margin-right:8px;
                        vertical-align: middle;
                        background: url("/lg5-common/images/CS/icon-check-wht.svg") no-repeat 0 0/100% 100%; // BTOCSITE-14407 WSG 수정 : 그라데이션 사용 제거 건
                    }
                }
            }
        }
        .btn-wrap {
            margin-top:24px;

            .btn-login {
                display: inline-block;
                width:126px;
                color:#fff; // BTOCSITE-14407 WSG 수정 : 그라데이션 사용 제거 건
                font-size:16px;
                text-align: center;
                line-height:44px;
                border-radius:25px;
                border:1px solid #fff; // BTOCSITE-14407 WSG 수정 : 그라데이션 사용 제거 건
                box-sizing: border-box;

            }
        }

        @include mobile {
            padding:40px 0;

            .login-tit {
                margin-bottom:24px;
                font-size:24px;
                line-height:30px;
            }
            .login-useful-list {
                ul {
                    font-size:14px;
                    line-height: 22px;
    
                    > li {
                        & + li {
                            margin-top:0;
                        }
                        &:before {
                            content:"";
                            width:16px;
                            height:16px;
                            margin-right:6px;
                        }
                    }
                }
            }
            .btn-wrap {
                margin-top:24px;
    
                .btn-login {
                    width:110px;
                    font-size:14px;
                    line-height:38px;
                    border-radius:21px;
                    border-width:1px;
                }
            }
        }
    }
}

.slick-controls .slick-dots, .slick-controls .slick-dot-container {
    margin-right:0;
}

/*고객지원 메인 popup용 (이미지 컨텐츠 인 경우 )임시 소스*/
// .ui_modal_wrap.init-type {
//     .popup-wrap {
//         height: 660px;
//         @include mobile {
//             height: 556px;
//             .pop-header {
//                 .tit {
//                     span {
//                         font-size: 15px;
//                     }
//                 }
//             }
//         }
//         .pop-conts.support {
//             > .mCustomScrollBox {
//                 > .mCSB_container {
//                     padding: 0 40px;
//                 }
//             }
//             @include mobile {
//                 padding: 0 16px;
//             }
//         }
//     }
// }

//히어로 영역
.home-hero {
	padding: 26px 20px 36px;
	background: url('/lg5-common/images/CS/bg_home_hero.png') no-repeat 0 0 / cover;
	.cont-top-tit {
		font-size: 20px;
		font-weight: 700;
		line-height: 26px;
        .point {
            color:#ea1917;
        }
	}
	.login-induce {
		position: relative;
		margin-top: 20px;
		.login-induce-ph {
			font-size: 12px;
		}
		.btn-login {
			position: absolute;
			right: 0;
			top: -10px;
			min-width: 36px;
		}
	}
	.product-regist {
		position: relative;
		margin-top: 20px;
		.product-regist-ph {
			font-size: 12px;
            line-height:22px;
			.flag-wrap {
				margin-right: 6px;
				vertical-align: super;
			}
			strong {
				font-weight: 700;
				color: #ea1917;
			}
		}
		.btn-regist {
			position: absolute;
			right: 0;
			top: -3px;
			padding: 7px 30px 7px 17px;
            border-radius:16px;
			&:before {
				content: '';
				position: absolute;
				right: 17px;
				top: 10px;
				display: block;
				width: 1px;
				height: 11px;
				background: #fff;
			}
			&:after {
				content: '';
				position: absolute;
				right: 12px;
				top: 15px;
				display: block;
				width: 11px;
				height: 1px;
				background: #fff;
			}
		}
	}
}
.home-hero.registered {
	padding-bottom: 56px;
}


//히어로 영역 - 제품등록
.registered-pdt {
	margin-top: -36px;
	padding: 0 16px;
	.cont-narrow {
		border: 1px solid #666;
		border-radius: 8px;
		background: #fff;
	}
	a {
		position: relative;
		display: block;
		padding: 15px 16px;
		&:after {
			content: '';
			position: absolute;
			right: 16px;
			top: 50%;
			display: block;
			width: 16px;
			height: 16px;
			margin-top: -8px;
			background: url('/lg5-common/images/icons/btn-arr-down-64.svg') no-repeat 50% 50% / 20px;
		}
	}
	.pdt-group {
		font-weight: 700;
		font-size: 15px;
	}
	.pdt-info {
		margin-top: 6px;
		.flag {
			padding: 0 2px;
			margin-right: 3px;
			border: 1px solid #aaa;
			border-radius: 4px;
			font-size: 12px;
			color: #666;
		}
		.serial-num {
			font-size: 11px;
			color: #666;
		}
	}
}


// 홈링크
.home-links {
	padding: 0 30px;
	margin-top: 24px;
	ul {
		display: flex;
		justify-content: space-between;
		text-align: center;
		li {
			a {
				position: relative;
				display: block;
				font-size: 12px;
				line-height: 15px;
				&:before {
					content: '';
					display: block;
					width: 44px;
					height: 44px;
					border-radius: 22px;
					margin: 0 auto 4px;
					background-color: #f9f9f9;
					background-repeat: no-repeat;
					background-size: 32px;
					background-position: 50% 50%;
				}
				.badge {
					position: absolute;
					right: 0;
					top: 0;
					width: 14px;
					height: 14px;
					border-radius: 7px;
					font-size: 10px;
					line-height: 14px;
					text-align: center;
					background: #ea1917;
					color: #fff;
				}
			}
			
				a.icon-center {
					&:before {
						background-image: url('/lg5-common/images/CS/ico_find_center.svg');
					}
				}
			
			
				a.icon-engin {
					&:before {
						background-image: url('/lg5-common/images/CS/ico_reservation.svg');
					}
				}
			
			
				a.icon-res {
					&:before {
						background-image: url('/lg5-common/images/CS/ico_reservation_result.svg');
					}
				}
			
			
				a.icon-down {
					&:before {
						background-image: url('/lg5-common/images/CS/ico_download_center.svg');
					}
				}
			
		}
	}
}


// 많이찾는질문
.popularQna {
	padding: 0 20px;
	margin-top: 44px;
	.popularQna-title {
		display: block;
		position: relative;
        padding-right:60px;
        line-height:24px;
		font-size: 18px;
		.popularQna-title-pdt {
			color: #ea1917;
		}
		a {
			position: absolute;
			right: 0px;
			top: 5px;
			padding-right: 15px;
			font-size: 13px;
			font-weight: normal;
            line-height:1;
			color: #666;
			&:after {
				content: '';
				position: absolute;
				right: 0;
				top: 1px;
				display: inline-block;
				width: 12px;
				height: 12px;
				background: url('/lg5-common/images/icons/btn-arr-24-gray.svg') no-repeat 0 0 / 12px;
			}
		}
	}
	.popularQna-list {
		ul {
			li {
				border-top: 1px solid #f4f4f4;
				&:first-child {
					border-top: none;
				}
				a {
					position: relative;
					display: block;
					padding: 18px 0 18px 20px;
					&:before {
						content: 'Q';
						position: absolute;
						left: 0;
						top: 19px;
						font-size: 14px;
						font-weight: bold;
						color: #ea1917;
					}
					.title {
                        height:auto;
                        line-height:17px;
						font-size: 14px;
                        font-weight:700;
                        @include textEllipsisClamp(2);
					}
					.article-info {
						position: relative;
						margin-top: 10px;
						font-size: 11px;
						color: #666;
						.count {
							position: absolute;
							right: 0;
							top: 0;
							.views {
								position: relative;
								display: inline-block;
								margin-left: 4px;
								padding-left: 6px;
								//&:before {
								//	content: '';
								//	position: absolute;
								//	left: 0;
								//	top: 0;
								//	display: block;
								//	width: 1px;
								//	height: 12px;
								//	background: #ddd;
								//}
							}
						}
					}
				}
			}
		}
	}
}


//빠른 링크
.home-quicklink {
	padding: 0 16px;
	margin-top: 24px;
    margin-bottom: 41px;
	ul {
		text-align: center;
		font-size: 0;
		border-radius: 8px;
		box-shadow: 2px 4px 14px 0 rgba(0, 0, 0, 0.14);
		li {
			position: relative;
			display: inline-block;
			&:nth-child(1) {
				width: 38%;
			}
			&:nth-child(2) {
				width: 32%;
			}
			&:nth-child(3) {
				width: 30%;
			}
			&:before {
				content: '';
				display: block;
				position: absolute;
				left: 0;
				top: 22px;
				width: 1px;
				height: 16px;
				background: #eee;
			}
			&:first-child {
				&:before {
					display: none;
				}
			}
			a {
				display: block;
				padding: 23px 0;
				font-size: 13px;
			}
		}
	}
}


// 제품등록팝업
.ownProductList {
	ul {
		li {
			padding: 20px;
			margin-top: 12px;
			border: 1px solid #ddd;
			border-radius: 8px;
			&:first-child {
				margin-top: 0;
			}
			.rdo-wrap {
				width: 100%;
			}
			label {
				width: 100%;
                line-height:22px;
				span {
					display: block;
				}
				.group {
					font-size: 10px;
					color: #ea1917;
				}
				.name {
					font-size: 13px;
					font-weight: bold;
                    @include textEllipsisClamp(1);
				}
				.serial {
					margin-top: 3px;
					font-size: 11px;
					color: #666;
				}
				&:after {
					top: 50%;
					margin-top: -12px;
				}
			}
		}
	}
}
#productRegistrationPopup {
	.pop-header {
		.tit {
			em {
				color: #ea1917;
			}
            i {
                font-size:14px;
                font-style:normal;
                font-weight:400;
                color:#666;
            }
		}
	}
}

// BTOCSITE-70096 고객지원 > 메인 리뉴얼
// 히어로 영역
.main-service-wrap {
    .common-banner.main-service {
        position: relative;
        margin-top: 0;
        padding-top: 0;
        border: none;
        // max-height: 170px;
        overflow: hidden;
        .banner {
            padding: 0;
        }
        .bann_head {
            display: none;
        }
        .bann_content {
            .main-service-slider {
                & > .slide-item {
                    &:not(:first-of-type) {
                        display: none;
                    }
                }
            }
            .slide-item {
                .slide-item-wrap {
                    position: relative;
                    // max-height: 170px;
                    overflow: hidden;
                    .slide-thumb-area {
                        display: block;
                        img {
                            width: 100%;
                            height: 100%;
                            &.pc-only {
                                display: none;
                            }
                        }
                    }
                    .slide-cont-area {
                        position: absolute;
                        top: 32px;
                        left: 20px;
                        .slide-title {
                            font-weight: 700;
                            font-size: 20px;
                            line-height: 25px;
                            span {
                                display: block;
                            }
                            .font-white {
                                color: white;
                            }
                            .font-small {
                                font-weight: 400;
                                font-size: 15px;
                                line-height: 22px;
                                margin-top: 4px;
                            }
                        }
                    }
                }
            }
            .slide-cover {
                font-size: 0;
            }
        }
        .slide-count {
            z-index: 1;
            position: absolute;
            bottom: 14px;
            right: 20px;
            display:inline-block;
            height:24px;
            border-radius:24px;
            padding:0 5px 0 0;
            background:rgba(0,0,0,0.35);
            color:#fff;
            font-size: 10px;
            line-height:24px;
        }
        .btn-play {
            z-index: 1;
            position: relative;
            bottom:initial;
            right:initial;
            display: inline-block;
            width:24px;
            height:24px;
            background: no-repeat right 3px / 16px auto url('/lg5-common/images/CS/icon/icon_video_pause.svg');
            &.pause {
                background: no-repeat right 4px / 16px auto url('/lg5-common/images/CS/icon/icon_video_play.svg');
            }
        }
        .btn-prev {
            position: relative;
            top: -1px;
            display: inline-block;
            width: 16px;
            height: 16px;
            margin-right: 3px;
            &::before {
                content: '';
                display: block;
                width: 16px;
                height: 16px;
                background-size: 16px;
                background-repeat: no-repeat;
                background-image: url('/lg5-common/images/icons/btn-arr-20x20-lt.svg');
            }
        }
        .btn-next {
            position: relative;
            top: -1px;
            display: inline-block;
            width: 16px;
            height: 16px;
            margin-left: 3px;
            &::before {
                content: '';
                display: block;
                width: 16px;
                height: 16px;
                background-size: 16px;
                background-repeat: no-repeat;
                background-image: url('/lg5-common/images/icons/btn-arr-20x20-rt.svg');
            }
        }

    }
}
// 센터 영역
.service-center-wrap {
    position: relative;
    border: 1px solid #eaeaea;
    border-radius: 8px;
    padding: 15px 14px;
    margin: 20px 16px 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .sort-select-wrap {
        position: relative;
        padding-left: 16px;
        select {
            position: relative;
            top: 0;
            font-size: 14px;
            line-height: 20px;
        }
        &::before {
            content: '';
            display: inline-block;
            width: 14px;
            height: 14px;
            position: absolute;
            left: 0;
            top: 3px;
            background: no-repeat center center / 14px auto url('/lg5-common/images/CS/icon/icon_place.svg');
        }
        
        .ui-selectbox-wrap {
            .ui-selectbox-list {
                border: 1px solid #aaa;
                ul {
                    li {
                        a {
                            color: #666;
                            text-align: left;
                        }
                        &.on a {
                            background: #f6f6f6;
                        }
                    }
                }
                ul li.on a {
                    background: #f6f6f6;
                }
            }
            
            .ui-select-button {
                padding-right: 28px;
                .ui-select-text {
                    color: #0f0f0f;
                }
                .ico {
                    top: calc(50% - 2px);
                    width: 20px;
                    height: 20px;
                    border: 1px solid #ddd;
                    border-radius: 50%;
                    background: no-repeat center center / 100% auto #fff url('/lg5-common/images/icons/btn-arr-20x20-black.svg');
                    rotate: 90deg;
                }
            }
        }
    }
    .btn-center-detail {
        color: #0f0f0f;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;

        &::after {
            content: '';
            position: relative;
            top: -2px;
            display: inline-block;
            width: 14px;
            height: 14px;
            margin-left: 4px;
            vertical-align: middle;
            background: no-repeat center / 100% auto url('/lg5-common/images/CS/ico_right_16.svg');
        }
    }
}

// 공지사항
.home-notice-wrap {
    &.home-notice {
        height: 52px;
        border-top: none;
        background-color: #f6f6f5;
        .notice-inner {
            padding: 0 0 0 20px;
            max-width: 100%;
        }
        .notice-list-wrap {
            padding: 0 120px 0 0;
            color: #0f0f0f;
            font-size: 14px;
            .notice-list {
                line-height: 52px;
                height: 52px;
            }
        }
        .slide-count {
            z-index: 1;
            position: absolute;
            top: 16px;
            right: 20px;
            display:inline-block;
            border-radius:24px;
            height:24px;
            padding:0 5px 0 0;
            background:rgba(0,0,0,0.35);
            color:#fff;
            font-size: 10px;
            line-height:24px;
        }
        .btn-play {
            z-index: 1;
            position: relative;
            top:initial;
            right:initial;
            transform: initial;
            display: inline-block;
            width:24px;
            height:24px;
            background: no-repeat right 3px / 16px auto url('/lg5-common/images/CS/icon/icon_video_pause.svg');
            &.pause {
                background: no-repeat right 4px / 16px auto url('/lg5-common/images/CS/icon/icon_video_play.svg');
            }
        }
        .btn-prev {
            position: relative;
            top: -1px;
            display: inline-block;
            width: 16px;
            height: 16px;
            margin-right: 3px;
            &::before {
                content: '';
                display: block;
                width: 16px;
                height: 16px;
                background-size: 16px;
                background-repeat: no-repeat;
                background-image: url('/lg5-common/images/icons/btn-arr-20x20-lt.svg');
            }
        }
        .btn-next {
            position: relative;
            top: -1px;
            display: inline-block;
            width: 16px;
            height: 16px;
            margin-left: 3px;
            &::before {
                content: '';
                display: block;
                width: 16px;
                height: 16px;
                background-size: 16px;
                background-repeat: no-repeat;
                background-image: url('/lg5-common/images/icons/btn-arr-20x20-rt.svg');
            }
        }
    }
}

// 로그인 영역
.login-info-wrap {
    padding: 0 16px;
    margin-top: 20px;
    .box-area {
        border-radius: 8px;
        padding: 24px 24px 28px 28px;

        &.login-type {
            background: url('/lg5-common/images/CS/icon_main_login.svg') no-repeat #f6f6f6;
            background-size: 85px auto;
            background-position: right 18px bottom 24px;
        }
        &.my-type {
            background: url('/lg5-common/images/CS/icon_main_my_info.svg') no-repeat #f6f6f6;
            background-size: 85px auto;
            background-position: right 18px bottom 24px;

        }
        &.nonbox-type {
            padding: 10px 0 0 0;
        }
    }

    &:has(.btn){
        .text {
            margin-bottom: 12px;
        }
    }
    &:has(.btn-login){
        .text {
            margin-bottom: 20px;
        }
    }

    .text {
        color: #0F0F0F;
        strong {
            display: block;
            font-size: 18px;
            line-height: 24px;
            font-weight: 700;
            em {
                color: $color-primary;
            }
        }
        span {
            display: block;
            font-size: 12px;
            line-height: 18px;
            font-weight: 400;
        }
    }
}

// 최근 이용 서비스
.recently-reserv-wrap {
    padding: 0 16px;
    margin-top: 20px;
    &.bg {
        background-color: #F9F9F9;
        padding: 24px 16px;
        .reserv-box {
            background-color: #fff;
        }

    }
    // BTOCSITE-83209 고객지원 > 메인 > 마이영역 수정
    .reserv-title {
        font-weight: 700;
        font-size: 15px;
        line-height: 22px;
        margin-bottom: 12px;
    }
    .reserv-list {
        margin: 0 -8px;
        & > .reserv-box {
            &:not(:first-of-type) {
                display: none;
            }
        }
        &:has(.slick-dots){
            padding-bottom: 18px;

        }
        .reserv-box {
            border: 1px solid #666;
            border-radius: 8px;
            padding: 16px;
            margin: 0 8px;
            .reserv-area {
                a {
                    .reserv-number {
                        &::after{
                            content: "";
                            display: inline-block;
                            position: relative;
                            width: 10px;
                            height: 10px;
                            background: url(/lg5-common/images/icons/btn-arr-16-down.svg) no-repeat center center;
                            background-size: 14px auto;
                            transform: rotate(-90deg);
                            opacity: 0.9;
                            margin-left: 4px;
                        }
                    }
                }
                p {
                 &:not(:last-of-type) {
                    margin-bottom: 8px;
                 }
                }
                .reserv-number {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 18px;
                    color: #666;
                    .flag {
                        display: inline-block;
                        background-color: rgba(228, 228, 228, 0.7);
                        font-weight: 500;
                        font-size: 11px;
                        line-height: 11px;
                        color: #000;
                        margin-right: 6px;
                        border-radius: 4px;
                        padding: 3px 4px 4px 4px;
                        height: 18px;
                    }
                }
                .reserv-topic {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    color: #0F0F0F;
                    word-wrap: break-word;
                    strong {
                        font-weight: 700;
                    }

                }
                .reserv-date {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 18px;
                    color: #666;
                    span {
                        &:not(:first-of-type) {
                            &::before {
                                content: '';
                                display: inline-block;
                                width: 1px;
                                height: 10px;
                                background-color: #ddd;
                                position: relative;
                                left: 0;
                                top: 0;
                                margin: 0 4px;
                            }
                        }
                    }
                }
            }
            .btn-area {
                border-top: 1px solid #eee;
                margin-top: 12px;
                padding-top: 12px;
                display: flex;
                justify-content: center;
                a, button {
                    position: relative;
                    display: inline-block;
                    height: 20px;
                    flex: 1;
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 20px;
                    color: #0F0F0F;
                    text-align: center;
                    vertical-align: middle;
                    &:not(:first-child) {
                        &::before {
                            content: '';
                            width: 1px;
                            height: 18px;
                            background-color: #eee;
                            position: absolute;
                            top: 0;
                            left: 0;
                        }
                    }
                    span {
                        display: inline-block;
                        position: relative;
                        &::after {
                            content: '';
                            position: absolute;
                            top: calc(50% - 6px);
                            right: -12px;
                            display: inline-block;
                            width: 12px;
                            height: 12px;
                            background: url(/lg5-common/images/CS/ico_right_16.svg) no-repeat center center / 100% auto;
                        }
                    }
                }
            }
        }
        .slick-dots {
            bottom: -4px;
            padding: 0 5px;
            li {
                padding: 2px 4px;
    
            }
        }
    }
}

// 링크 영역
.home-links-wrap {
    padding: 0 16px;
    margin-top: 20px;
	ul {
        position: relative;
        display: grid;
        grid-template-columns:1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        grid-gap: 20px 1rem;
		li {
			a {
				position: relative;
				display: block;
				font-size: 12px;
				line-height: 18px;
                font-weight: 400;
                text-align: center;
				&:before {
					content: '';
					display: block;
					width: 56px;
					height: 56px;
					border-radius: 56px;
					margin: 0 auto 4px;
					background-color: #f9f9f9;
					background-repeat: no-repeat;
					background-size: 32px;
					background-position: 50% 50%;
				}


                &.icon-reserv {
                    &:before {
                        background-image: url('/lg5-common/images/CS/icon/icon_reservation_v2.svg');
                    }
                }
                &.icon-result {
                    &:before {
                        background-image: url('/lg5-common/images/CS/icon/icon_reserv_result_v2.svg');
                    }
                }
                &.icon-contact {
                    &:before {
                        background-image: url('/lg5-common/images/CS/icon/icon_contact_us_v2.svg');
                    }
                }
                &.icon-manual {
                    &:before {
                        background-image: url('/lg5-common/images/CS/icon/icon_manual_v2.svg');
                    }
                }
                &.icon-visit {
                    &:before {
                        background-image: url('/lg5-common/images/CS/icon/icon_visit_v2.svg');
                    }
                }
                &.icon-goods {
                    &:before {
                        background-image: url('/lg5-common/images/CS/icon/icon_goods_v2.svg');
                    }
                }
                &.icon-wash {
                    &:before {
                        background-image: url('/lg5-common/images/CS/icon/icon_wash_v2.svg');
                    }
                }
                &.icon-removal {
                    &:before {
                        background-image: url('/lg5-common/images/CS/icon/icon_removal_v2.svg');
                    }
                }
			}
		}
	}
}

// 가전 꿀팁 배너
.related-pages-wrap {
    .common-banner.related-pages {
        margin-top: 40px;
        border-top: 0;

        .item-thumb {
            height: 100px;
            overflow: hidden;
        }
        .item {
            > a img {
                object-fit: cover;
            }
        }
        .banner-tit {
            display: block !important;
            font-size: 20px;
            line-height: 25px;
            font-weight: 700;
            margin-bottom: 20px;
        }
        .slide-count {
            z-index: 1;
            position: absolute;
            bottom: 10px;
            right: 10px;
            display:inline-block;
            height:24px;
            border-radius:24px;
            padding:0 5px 0 0;
            background:rgba(0,0,0,0.35);
            color:#fff;
            font-size: 10px;
            line-height:24px;
        }
        .btn-play {
            z-index: 1;
            position: relative;
            bottom:initial;
            right:initial;
            display: inline-block;
            width:24px;
            height:24px;
            background: no-repeat right 3px / 16px auto url('/lg5-common/images/CS/icon/icon_video_pause.svg');
            &.pause {
                background: no-repeat right 4px / 16px auto url('/lg5-common/images/CS/icon/icon_video_play.svg');
            }
        }
        .btn-prev {
            position: relative;
            top: -1px;
            display: inline-block;
            width: 16px;
            height: 16px;
            margin-right: 3px;
            &::before {
                content: '';
                display: block;
                width: 16px;
                height: 16px;
                background-size: 16px;
                background-repeat: no-repeat;
                background-image: url('/lg5-common/images/icons/btn-arr-20x20-lt.svg');
            }
        }
        .btn-next {
            position: relative;
            top: -1px;
            display: inline-block;
            width: 16px;
            height: 16px;
            margin-left: 3px;
            &::before {
                content: '';
                display: block;
                width: 16px;
                height: 16px;
                background-size: 16px;
                background-repeat: no-repeat;
                background-image: url('/lg5-common/images/icons/btn-arr-20x20-rt.svg');
            }
        }
    }
}
// 가전 꿀팀 목록
.popular-list-wrap {
    padding: 0 16px;
    margin-top: 20px;
    ul {
        padding: 0 8px;
        li {
            padding: 20px 0 20px 0;
            border-bottom: 1px solid #f8f8f8;
            &:first-child{
                padding-top: 0;
            }
            &:last-child{
                padding-bottom: 0;
                border-bottom: none;
            }
            a {
                position: relative;
                min-height: 50px;
                display: flex;
                align-items: flex-start;
                flex-direction: column;
                justify-content: center;
                padding: 0 32px 0 70px;
                &::after {
                    content: "";
                    position: absolute;
                    top: calc(50% - 6px);
                    right: 0;
                    width: 12px;
                    height: 12px;
                    background: url(/lg5-common/images/icons/btn-arr-24x24-black.svg) no-repeat center center;
                    background-size: 14px auto;
                }
            }
            p {
                &:first-of-type {
                    position: absolute;
                    left: 0;
                    top: calc(50% - 25px);
                }
            }
            .icon-movie {
                position: absolute;
                top: 1px;
                right: -2px;
                display: inline-block;
                width:18px;
                height:18px;
                background:url(/lg5-common/images/CS/icon/icon_movie.svg) no-repeat;
                background-size:18px;
                vertical-align: middle;
            }
            .icon {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 50px;
                height: 50px;
                border-radius: 50%;
                background-color: #f9f9f9;
                padding: 8px;
                img {
                    width: 26px;
                    height: 26px;
                }
            }
            .type {
                font-size: 12px;
                line-height: 18px;
                font-weight: 700;
            }
            .title {
                position: relative;
                width: 100%;
                font-size: 14px;
                line-height: 20px;
                font-weight: 400;
                margin-top: 4px;
                padding-right: 15px;
                .txt {
                    @include textEllipsisClamp(2);
                }
               
            }
        }
    }
}
// 검색 영역
.keyword-search-wrap {
    &.keyword-search  {
        padding: 0 16px !important;
        margin: 20px 0 0 0 !important;
        &.on {
            .search-recommend {
                display: none;
            }
        }

        .search-box {
            max-width: 100%;
            background-color: #f6f6f6;
            border-radius: 8px;
            padding-top: 20px;
            padding-bottom: 20px;

            .search-title {
                margin-bottom: 12px;
                strong {
                    display: block;
                    font-size: 14px;
                    line-height: 20px;
                    font-weight: 700;
                    text-align: center;
                }
            }
            .search-input {
                padding: 0 16px;
                input[type=text] {
                    background-color: white;
                    border: 1px solid #eee;
                    height: 46px;
                    &::placeholder{
                        color:#666;
                    }
                }
            }
            .btn-search {
                right: 30px;
                width: 30px;
            }
            .btn-delete {
                right: 65px;
            }

        }

        .search-recommend {
            position: absolute;
            left: 16px;
            top: 72px;
            padding:0 0 0 20px;
            margin:0;
            .btn-recommend {
                font-size: 15px;
                color: #666;
                display: flex;
                align-items: center;
                height: 46px;
            }
        }
    }
}

// 보유제품
.own-product-wrap {
    padding: 0 16px;
    margin-top: 20px;
    .total-count-area {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;
        margin-bottom: 12px;
        gap: 8px;
        .btn-regist {
            font-size: 14px;
            line-height: 20px;
            padding: 4px 0px;
            em {
                color: $color-primary;
            }
            .btn-link {
                text-decoration: none;
                padding-right: 20px;
                &::after {
                    width: 16px;
                    height: 16px;
                    background-size: 16px;
                }
            }
        }
        #serviceBtn {
            width: 100%;
            font-size: 13px;
            height: 40px;
        }
    }
    .product-list {
        .product-box {
            border: 1px solid #ddd;
            border-radius: 12px;
            // padding: 0 16px;
            overflow: hidden;
            & ~ .product-box {
                margin-top: 10px;
            }
            &.open {
               border-color: #0f0f0f; 
                .prd-head {
                    button, a {
                        &::after {
                            transform:rotate(-90deg);
                        }
                    }
                }
                .prd-cont {
                    display: block;
                }
            }
            .prd-head {
                button, a {
                    position: relative;
                    width: 100%;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    text-align: left;
                    padding: 15px 16px 20px 16px;
                    &::after {
                        content: "";
                        display: inline-block;
                        position: absolute;
                        top: calc(50% - 8px);
                        right: 16px;
                        width: 16px;
                        height: 16px;
                        background: url(/lg5-common/images/icons/btn-arr-16-down.svg) no-repeat center center;
                        background-size: 14px auto; 
                    }
                }
                .model-ico {
                    width: 55px;
                    height: 55px;
                    img {
                        width: 100%;
                        height: auto;
                    }
                }
                .model-ttl {
                    flex: 1;
                    margin-left: 12px;
                    margin-right: 17px;
                    .model-name {
                        color: #0f0f0f;
                        font-weight: 700;
                        font-size: 15px;
                        line-height: 22px;
                        @include textEllipsisClamp(2);
                    }
                    .model-code {
                        display: block;
                        color: #666;
                        font-weight: 400;
                        font-size: 11px;
                        line-height: 15px;
                        margin-top: 6px;
                    }
                }
            }
            .prd-cont {
                display: none;
                border-top:1px solid #eee;
                padding-bottom: 20px;
                margin: 0 16px;

                .home-links-wrap {
                    padding: 0;
                    margin-bottom: 24px;
                    ul {
                        position: relative;
                        display: grid;
                        grid-template-columns:1fr 1fr 1fr 1fr;
                        grid-template-rows: 1fr auto;
                        grid-gap: 0 1rem;
                        li {
                            &:nth-of-type(5),
                            &:nth-of-type(6),
                            &:nth-of-type(7),
                            &:nth-of-type(8) {
                                margin-top: 20px;
                            }
                        }
                    }
                }

                .supplies-wrap {
                    border-top:1px solid #eee;
                    padding-top:20px;
                    .supplies-title-wrap {
                        position: relative;
                    }
                    .supplies-title {
                        color: #0f0f0f;
                        font-weight: 700;
                        font-size: 15px;
                        line-height: 22px;
                        margin-bottom: 16px;
                    }
                    .supplies-btn {
                        position: absolute;
                        top: 0;
                        right: 0;
                        text-decoration: none;
                    }
                    .supplies-list {
                        ul {
                            display: flex;
                            overflow-x: auto;
                            overflow-y: hidden;
                            margin-right: -16px;
                            padding-bottom: 24px;
                            li {
                                position: relative;
                                display: inline-block;
                                width: 130px;
                                &~li {
                                    margin-left: 10px;
                                }
                                &:last-child {
                                    margin-right: 16px;
                                }
                                .prd-img {
                                    display: inline-block;
                                    width: 130px;
                                    height: 130px;
                                    position: relative;
                                    text-align: center;
                                    background-color: #fff;
                                    a {
                                        z-index: 1;
                                        position: absolute;
                                        top: 0;
                                        bottom: 0;
                                        left: 0;
                                        right: 0;
                                    }

                                    .flag {
                                        position: absolute;
                                        top: 6px;
                                        right: 6px;
                                        font-size: 11px;
                                        color: #000;
                                        border-radius: 4px;
                                        height: 18px;
                                        padding: 3px 4px 4px 4px;
                                        text-align: center;
                                        background-color: #e4e4e4;
                                        text-decoration: none;
                                        &.red {
                                            color: $color-primary;
                                            background-color: #fff5f8; // BTOCSITE-77476 [웹앱 접근성 개선활동] 전시_마이페이지
                                        }
                                    }
                                    img {
                                        width: 110px;
                                        height: 110px;
                                    }
                                    &::after {
                                        content: '';
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        width: 130px;
                                        height: 130px;
                                        border-radius: 8px;
                                        background-color: rgba(0, 0, 0, .03);
                                    }
                                }
                                .prd-name {
                                    color: #0f0f0f;
                                    font-weight: 400;
                                    font-size: 14px;
                                    line-height: 20px;
                                    margin-top: 22px;
                                    @include textEllipsis;
                                }
                                .model-code {
                                    color: #666;
                                    font-weight: 400;
                                    font-size: 11px;
                                    line-height: 15px;
                                    margin-top: 2px;
                                }
                                .price {
                                    height: 20px;
                                    color: #0f0f0f;
                                    font-weight: 500;
                                    font-size: 14px;
                                    line-height: 20px; 
                                    margin-top: 8px;
                                }
                                .cart {
                                    position: absolute;
                                    right: 0;
                                    bottom: 0;
                                    .btn-cart {
                                        display: inline-block;
                                        width: 32px;
                                        height: 32px;
                                        border-radius: 4px;
                                        background: url('/lg5-common/images/icons/icon-cart.svg') no-repeat #f9f9f9;
                                        background-position: center center;
                                        background-size: 20px auto;
                                    }
                                    .soldoutFlag {
                                        display: inline-block;
                                        vertical-align: middle;
                                        font-size: 12px;
                                        color: #fff;
                                        border-radius: 4px;
                                        background-color: #666;
                                        padding: 2px 4px 3px 4px;
                                    }
                                }
                            }
                        }
                    }

                }

                .recommend-wrap {
                    border-top:1px solid #eee;
                    padding-top:20px;
                    .recommend-title-wrap {
                        position: relative;
                    }
                    .recommend-title {
                        color: #0f0f0f;
                        font-weight: 700;
                        font-size: 15px;
                        line-height: 22px;
                        margin-bottom: 16px;
                    }
                    .recommend-btn {
                        position: absolute;
                        top: 0;
                        right: 0;
                        text-decoration: none;
                    }
                    .recommend-list {
                        li {
                            &:not(:first-of-type) {
                                margin-top: 10px;
                            }
                            a {
                                position: relative;
                                display: inline-block;
                                width: 100%;
                                color: #0f0f0f;
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 20px;
                                padding-left: 38px;
                                padding-right: 15px;
                                @include textEllipsis;

                            }
                            .flag {
                                position: absolute;
                                left: 0;
                                top: 0;
                                display: inline-block;
                                margin-right: 4px;
                                color: $color-primary;
                            }
                            .icon-movie {
                                position: absolute;
                                right: 0;
                                top: 1px;
                                display: inline-block;
                                width:18px;
                                height:18px;
                                background:url(/lg5-common/images/CS/icon/icon_movie.svg) no-repeat;
                                background-size:18px;
                                margin-left: 5px;
                                vertical-align: middle;
                            }
                        }

                    }
                }
            }
        }

        .btns-area {
            text-align: center;
            margin: 16px 0 0 0;
        }
        .btn-view-all {
            &::after {
                background: url(/lg5-common/images/icons/btn-arr-16-down.svg) no-repeat center center;
                background-size: 14px auto;
            }
        }
        .btn-view-close {
            &::after {
                background: url(/lg5-common/images/icons/btn-arr-16-down.svg) no-repeat center center;
                background-size: 14px auto;
                rotate: 180deg;
                margin-top: -10px;
            }
        }
    }
}

// 베스트 케어
.bestcare-wrap {
    &.bestcare-section {
        max-width: initial;
        padding-top: 40px;
        background-color: #f8f8f8;
        .bestcare-content {
            .bestcare-banner {
                background-color: #fff;
            }
        }
    }
}


// 다시 예약 팝업
#reReservationPopup {
    .pop-footer {
        border-top: 1px solid #ddd;
    }
    .pop-conts {
        padding: 44px 16px 36px 16px;
    }
    .pop-re-reserv {
        dl {
            display: flex;
            flex-direction: column;
            dt {
                font-size: 16px;
                font-weight: 700;
                line-height: 22px;
                text-align: center;
                em {
                    color: $color-primary;
                }
            }
            dd {
                margin-top: 20px;
            }
        }
        .rdo-box {
            border: 1px solid #ddd;
            border-radius: 8px;
            padding: 24px 20px;
            &:not(:first-of-type) {
                margin-top: 12px;
            }
            label {
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                color: #0F0F0F;
            }
            .txt-topic {
                font-size: 13px;
                font-weight: 400;
                line-height: 20px;
                color: $color-primary;
                margin-top: 6px;
            }
        }
    }
}

// BTOCSITE-83209 고객지원 > 메인 > 마이영역 수정
.servicePopup {
    display: none;
    z-index: 105;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 53px;
    height: 48px;
    padding: 0px 16px 16px 16px;
    background-color: white;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.14);
    transition: all 0.3s ease-in-out;
    &.active {
        height: 398px;
    }

    .compare-title {
        display: flex;
        align-items: center;
        position: static;
        padding: 0;
        height:48px;
        font-size: 0;
        @include clearfix;
        @include mobile {
            display: flex;
            height:48px;
            padding: 0;
            position: relative;
        }
        .title-text {
            flex: none;
            display: inline-block;
            color: #000;
            font-size: 13px;
            font-weight: 700;
            line-height: 20px;
        }
    }
    .more-arrow {
        display: inline-block;
        position: relative;
        width: 32px;
        height: 32px;
        opacity:1;

        @include mobile {
          width: 100%;
          height: 100%;
        }

        &:before {
          display: inline-block;
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          width: 32px;
          height: 32px;
          transform: translate(-50%, -50%);
          background: url('/lg5-common/images/icons/icon-arrow-down-24x24.svg') no-repeat 0 0;
          background-size:100% 100%;

          @include mobile {
            width: 16px;
            height: 16px;
            background-size: 100%;
          }
        }

        &.close {
          &:before {
            transform: translate(-50%, -50%) rotate(-180deg);
            // margin: -7px 0 0 -6px;
          }
        }
      }

    .btn.btn-confirm {
        width: 100%;
        position: relative;
        padding: 10px 25px;
        height: 40px;
        span {
            font-weight: 700;
            font-size: 13px;
            line-height: 20px;
            color: #fff;
            &::after {
                content: "";
                display: inline-block;
                position: relative;
                top: 3px;
                width: 16px;
                height: 16px;
                background: url(/lg5-common/images/icons/btn-arr-16-white.svg) no-repeat center center;
                background-size: 16px auto;
                margin-left: 2px;
            }
        }
    }
    .btn-close {
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 16px;
        right: 16px;
        @include mobile {
            right: 0px;
        }
    }
    .home-links-wrap {
        border-radius: 12px;
        border: 1px solid #ddd;
        padding: 16px;
        margin-top: 16px;
        .home-links-title {
            color: #0f0f0f;
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 12px;
        }
        ul {
            position: relative;
            display: grid;
            grid-template-columns:repeat(4, 58px);
            grid-template-rows: 1fr 1fr;
            justify-content: space-between;
            align-items: start;
            grid-gap: 20px 20px;
            padding: 0px 4px;
            li {
                min-width: 58px;
                width: 58px;
                a {
                    position: relative;
                    display: block;
                    font-size: 12px;
                    line-height: 18px;
                    font-weight: 400;
                    text-align: center;
                    &:before {
                        content: '';
                        display: block;
                        width: 56px;
                        height: 56px;
                        border-radius: 56px;
                        margin: 0 auto 4px;
                        background-color: #f9f9f9;
                        background-repeat: no-repeat;
                        background-size: 32px;
                        background-position: 50% 50%;
                    }
                    &.icon-reserv {
                        &:before {
                            background-image: url('/lg5-common/images/CS/icon/icon_reservation_v2.svg');
                        }
                    }
                    &.icon-result {
                        &:before {
                            background-image: url('/lg5-common/images/CS/icon/icon_reserv_result_v2.svg');
                        }
                    }
                    &.icon-contact {
                        &:before {
                            background-image: url('/lg5-common/images/CS/icon/icon_contact_us_v2.svg');
                        }
                    }
                    &.icon-manual {
                        &:before {
                            background-image: url('/lg5-common/images/CS/icon/icon_manual_v2.svg');
                        }
                    }
                    &.icon-visit {
                        &:before {
                            background-image: url('/lg5-common/images/CS/icon/icon_visit_v2.svg');
                        }
                    }
                    &.icon-goods {
                        &:before {
                            background-image: url('/lg5-common/images/CS/icon/icon_goods_v2.svg');
                        }
                    }
                    &.icon-wash {
                        &:before {
                            background-image: url('/lg5-common/images/CS/icon/icon_wash_v2.svg');
                        }
                    }
                    &.icon-removal {
                        &:before {
                            background-image: url('/lg5-common/images/CS/icon/icon_removal_v2.svg');
                        }
                    }
                }
            }
        }
    }
}



// 모바일 웹 전용
.mdevice {
    &.app {
        // BTOCSITE-83209 고객지원 > 메인 > 마이영역 수정
        .servicePopup {
            bottom: 0;
        }
    }
}